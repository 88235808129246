const namedCssColors: { [key: string]: string } = {
  aliceblue: 'f0f8ff',
  antiquewhite: 'faebd7',
  aqua: '00ffff',
  aquamarine: '7fffd4',
  azure: 'f0ffff',
  beige: 'f5f5dc',
  bisque: 'ffe4c4',
  black: '000000',
  blanchedalmond: 'ffebcd',
  blue: '0000ff',
  blueviolet: '8a2be2',
  brown: 'a52a2a',
  burlywood: 'deb887',
  cadetblue: '5f9ea0',
  chartreuse: '7fff00',
  chocolate: 'd2691e',
  coral: 'ff7f50',
  cornflowerblue: '6495ed',
  cornsilk: 'fff8dc',
  crimson: 'dc143c',
  cyan: '00ffff',
  darkblue: '00008b',
  darkcyan: '008b8b',
  darkgoldenrod: 'b8860b',
  darkgray: 'a9a9a9',
  darkgrey: 'a9a9a9',
  darkgreen: '006400',
  darkkhaki: 'bdb76b',
  darkmagenta: '8b008b',
  darkolivegreen: '556b2f',
  darkorange: 'ff8c00',
  darkorchid: '9932cc',
  darkred: '8b0000',
  darksalmon: 'e9967a',
  darkseagreen: '8fbc8f',
  darkslateblue: '483d8b',
  darkslategray: '2f4f4f',
  darkslategrey: '2f4f4f',
  darkturquoise: '00ced1',
  darkviolet: '9400d3',
  deeppink: 'ff1493',
  deepskyblue: '00bfff',
  dimgray: '696969',
  dimgrey: '696969',
  dodgerblue: '1e90ff',
  firebrick: 'b22222',
  floralwhite: 'fffaf0',
  forestgreen: '228b22',
  fuchsia: 'ff00ff',
  gainsboro: 'dcdcdc',
  ghostwhite: 'f8f8ff',
  gold: 'ffd700',
  goldenrod: 'daa520',
  gray: '808080',
  grey: '808080',
  green: '008000',
  greenyellow: 'adff2f',
  honeydew: 'f0fff0',
  hotpink: 'ff69b4',
  indianred: 'cd5c5c',
  indigo: '4b0082',
  ivory: 'fffff0',
  khaki: 'f0e68c',
  lavender: 'e6e6fa',
  lavenderblush: 'fff0f5',
  lawngreen: '7cfc00',
  lemonchiffon: 'fffacd',
  lightblue: 'add8e6',
  lightcoral: 'f08080',
  lightcyan: 'e0ffff',
  lightgoldenrodyellow: 'fafad2',
  lightgrey: 'd3d3d3',
  lightgray: 'd3d3d3',
  lightgreen: '90ee90',
  lightpink: 'ffb6c1',
  lightsalmon: 'ffa07a',
  lightseagreen: '20b2aa',
  lightskyblue: '87cefa',
  lightslategray: '778899',
  lightslategrey: '778899',
  lightsteelblue: 'b0c4de',
  lightyellow: 'ffffe0',
  lime: '00ff00',
  limegreen: '32cd32',
  linen: 'faf0e6',
  magenta: 'ff00ff',
  maroon: '800000',
  mediumaquamarine: '66cdaa',
  mediumblue: '0000cd',
  mediumorchid: 'ba55d3',
  mediumpurple: '9370d8',
  mediumseagreen: '3cb371',
  mediumslateblue: '7b68ee',
  mediumspringgreen: '00fa9a',
  mediumturquoise: '48d1cc',
  mediumvioletred: 'c71585',
  midnightblue: '191970',
  mintcream: 'f5fffa',
  mistyrose: 'ffe4e1',
  moccasin: 'ffe4b5',
  navajowhite: 'ffdead',
  navy: '000080',
  oldlace: 'fdf5e6',
  olive: '808000',
  olivedrab: '6b8e23',
  orange: 'ffa500',
  orangered: 'ff4500',
  orchid: 'da70d6',
  palegoldenrod: 'eee8aa',
  palegreen: '98fb98',
  paleturquoise: 'afeeee',
  palevioletred: 'd87093',
  papayawhip: 'ffefd5',
  peachpuff: 'ffdab9',
  peru: 'cd853f',
  pink: 'ffc0cb',
  plum: 'dda0dd',
  powderblue: 'b0e0e6',
  purple: '800080',
  red: 'ff0000',
  rosybrown: 'bc8f8f',
  royalblue: '4169e1',
  saddlebrown: '8b4513',
  salmon: 'fa8072',
  sandybrown: 'f4a460',
  seagreen: '2e8b57',
  seashell: 'fff5ee',
  sienna: 'a0522d',
  silver: 'c0c0c0',
  skyblue: '87ceeb',
  slateblue: '6a5acd',
  slategray: '708090',
  slate: '708090',
  snow: 'fffafa',
  springgreen: '00ff7f',
  steelblue: '4682b4',
  tan: 'd2b48c',
  teal: '008080',
  thistle: 'd8bfd8',
  tomato: 'ff6347',
  turquoise: '40e0d0',
  violet: 'ee82ee',
  wheat: 'f5deb3',
  white: 'ffffff',
  whitesmoke: 'f5f5f5',
  yellow: 'ffff00',
  yellowgreen: '9acd32',
}

function parseColor(testColor: string) {
  let match
  let hex = testColor

  const regex = /#?([a-fA-F0-9]{3})$/
  const hexRegex = /#?([a-fA-F0-9]{6})$/
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/
  const rgbaRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d\.]+)\)/
  const hslRegex = /hsl\(\s*(\d+)\s*,\s*(\d+(?:\.\d+)?)%\s*,\s*(\d+(?:\.\d+)?)%\)/

  if (testColor && hexRegex.test(testColor)) {
    hex = testColor.toLowerCase().replace('#', '')
  } else if (regex.test(testColor)) {
    match = regex.exec(testColor)
    if (match) {
      const r = match[1][0]
      const g = match[1][1]
      const b = match[1][2]
      hex = (r + r + g + g + b + b).toLowerCase()
    }
  } else if (rgbRegex.test(testColor)) {
    match = rgbRegex.exec(testColor)
    if (match) {
      hex = rgbToHex(match[1], match[2], match[3])
    }
  } else if (rgbaRegex.test(testColor)) {
    match = rgbaRegex.exec(testColor)
    if (match) {
      const alphaHexString = alphaToHex(parseFloat(match[4]))
      hex = rgbToHex(match[1], match[2], match[3]) + alphaHexString
    }
  } else if (hslRegex.test(testColor)) {
    match = hslRegex.exec(testColor)
    if (match) {
      hex = hslToHex(parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3], 10))
    }
  } else if (colorNameToHex(testColor)) {
    hex = colorNameToHex(testColor)
  }
  return hex
}

function alphaToHex(alphaValue: number) {
  if (
    typeof alphaValue === 'undefined' ||
    alphaValue === null ||
    alphaValue > 1 ||
    isNaN(alphaValue)
  ) {
    alphaValue = 1
  } else if (alphaValue < 0) {
    alphaValue = 0
  }
  const alphaHexString = Math.round(alphaValue * 255)
    .toString(16)
    .padStart(2, '0')
  return alphaHexString
}

function rgbToHex(r: string, g: string, b: string) {
  r = parseInt(r, 10).toString(16).padEnd(2, '0')
  g = parseInt(g, 10).toString(16).padEnd(2, '0')
  b = parseInt(b, 10).toString(16).padEnd(2, '0')
  return `${r}${g}${b}`.toLowerCase()
}

function hslToHex(h: number, s: number, l: number) {
  let m1, m2, hue, r, g, b
  s /= 100
  l /= 100
  if (s === 0) r = g = b = l * 255
  else {
    if (l <= 0.5) m2 = l * (s + 1)
    else m2 = l + s - l * s
    m1 = l * 2 - m2
    hue = h / 360
    r = Math.round(hueToRgb(m1, m2, hue + 1 / 3))
    g = Math.round(hueToRgb(m1, m2, hue))
    b = Math.round(hueToRgb(m1, m2, hue - 1 / 3))
  }
  return rgbToHex(`${r}`, `${g}`, `${b}`)
}

function hueToRgb(m1: number, m2: number, hue: number) {
  let v
  if (hue < 0) hue += 1
  else if (hue > 1) hue -= 1
  if (6 * hue < 1) v = m1 + (m2 - m1) * hue * 6
  else if (2 * hue < 1) v = m2
  else if (3 * hue < 2) v = m1 + (m2 - m1) * (2 / 3 - hue) * 6
  else v = m1
  return 255 * v
}

function rgbToHsl(r: number, g: number, b: number) {
  r /= 255
  g /= 255
  b /= 255
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h = 0
  let s: number
  let l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        break
    }

    h /= 6
  }

  return { h, s, l }
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

function toGrayscale(hex: string) {
  let redIntensity = parseInt(hex.substring(0, 2), 16)
  let greenIntensity = parseInt(hex.substring(2, 4), 16)
  let blueIntensity = parseInt(hex.substring(4, 6), 16)
  let gray = redIntensity * 0.3 + greenIntensity * 0.59 + blueIntensity * 0.11
  let grayHex = parseInt(`${gray}`, 19).toString(16).padEnd(2, '0').toLowerCase()
  return grayHex + grayHex + grayHex
}

// formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
function calculateContrastRatio(val1: string, val2: string) {
  const hex1 = parseColor(val1)
  const hex2 = parseColor(val2)
  const L1 = getContrastL(hex1)
  const L2 = getContrastL(hex2)
  const ratio = (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
  const rating = getRating(ratio, false)
  const ratingBig = getRating(ratio, true)
  const ratingUiElement = ratio >= 3 ? 'AAA' : 'FAIL'
  return {
    ratio: Math.round(ratio * 10) / 10,
    rating: {
      big: ratingBig,
      normal: rating,
      ui: ratingUiElement,
    },
  }
}

function getContrastRgbValue(color: string) {
  let rgbColor = parseInt(color, 16) / 255
  rgbColor = rgbColor <= 0.03928 ? rgbColor / 12.92 : Math.pow((rgbColor + 0.055) / 1.055, 2.4)
  return rgbColor
}

function getContrastL(hex: string) {
  return (
    0.2126 * getContrastRgbValue(hex.substring(0, 2)) +
    0.7152 * getContrastRgbValue(hex.substring(2, 4)) +
    0.0722 * getContrastRgbValue(hex.substring(4, 6))
  )
}

function getRating(ratio: number, isBig: boolean) {
  if (isBig) {
    if (ratio > 4.5) {
      return 'AAA'
    }
    if (ratio > 3) {
      return 'AA'
    }
    return 'FAIL'
  }
  if (ratio > 7) {
    return 'AAA'
  }
  if (ratio > 4.5) {
    return 'AA'
  }
  return 'FAIL'
}

function colorNameToHex(color: string) {
  if (color && typeof namedCssColors[color.toLowerCase()] !== 'undefined') {
    return namedCssColors[color.toLowerCase()]
  }
  return ''
}

function shadeColor(color: string, percent: number) {
  return tintColor(color, percent * -1)
}

function tintColor(color: string, percent: number) {
  let R = parseInt(color.substring(0, 2), 16)
  let G = parseInt(color.substring(2, 4), 16)
  let B = parseInt(color.substring(4, 6), 16)

  R = parseInt(`${(R * (100 + percent)) / 100}`, 10)
  G = parseInt(`${(G * (100 + percent)) / 100}`, 10)
  B = parseInt(`${(B * (100 + percent)) / 100}`, 10)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return `${RR + GG + BB}`
}

export {
  parseColor,
  rgbToHsl,
  hexToRgb,
  toGrayscale,
  calculateContrastRatio,
  shadeColor,
  tintColor,
}
