import {
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { debugErrorLogger } from 'common'
import StorageFactory from 'storageFactory'
import { testFeatureActive } from 'features'
import { calculateContrastRatio, parseColor } from 'colors'
import {
  blueBrandingProps,
  blue2BrandingProps,
  defaultBrandingProps,
  gawdAwfulBrandingProps,
  darkBrandingProps,
  wmdBrandingProps,
} from './themes'
import customFetch from 'apis/customFetch'
import { useAppContext } from 'context/useAppContext'

const brandingThemes = {
  blue: blueBrandingProps,
  blue2: blue2BrandingProps,
  wmd: wmdBrandingProps,
  awful: gawdAwfulBrandingProps,
  dark: darkBrandingProps,
  default: defaultBrandingProps,
}

const noBrandingProps: __KeyValue = {}

interface BrandingContextType {
  brandingProps?: __KeyValue
  cssProps?: __KeyValue
  setBrandingProps?: (brandingProps: __KeyValue) => void
  setCssProps?: (cssProps: __KeyValue) => void
  updateBrandingProp?: (name: string, value: string) => void
  getBrandingProp?: (name: string) => string
}

const defaultState: BrandingContextType = {
  brandingProps: {},
  cssProps: {},
  setBrandingProps: (brandingProps: __KeyValue) => {
    debugErrorLogger(
      `Branding provider not set up for branding props: ${JSON.stringify(brandingProps)}`,
    )
  },
  setCssProps: (cssProps: __KeyValue) => {
    debugErrorLogger(`Branding provider not set up for css props: ${JSON.stringify(cssProps)}`)
  },
  updateBrandingProp: (name: string, value: string) => {
    debugErrorLogger(`Branding provider not set up for updating props: ${name}: ${value}`)
  },
  getBrandingProp: (name: string) => {
    debugErrorLogger(`Branding provider not set up for getting props: ${name}`)
    return ''
  },
}

const cssPropsMap: __KeyValue = {
  'primary-color': '--color-primary',
  'primary-light-color': '--color-primary-light',
  'primary-x-light-color': '--color-primary-x-light',
  'primary-dark-color': '--color-primary-dark',
  'secondary-color': '--color-accent',
  'secondary-x-light-color': '--color-accent-light',
  'dark-color': '--color-neutral-x-dark',
  'neutral-color': '--color-neutral-minus',
  'neutral-dark-color': '--color-neutral-dark',
  'neutral-medium-color': '--color-neutral',
  'white-color': '--color-neutral-xx-light',
  'font-family': '--font-family-primary',
  'font-family-decorative': '--font-family-serif',
  'font-size-base': '--base-font-size',
  'header-color': '--header-base-color',
  'on-header-text-color': '--header-fill-color',
  'subheader-color': '--sub-header-base-color',
  'on-subheader-text-color': '--sub-header-fill-color',
  'page-background-color': '--color-background',
  'on-page-text-color': '--color-foreground',
  'links-buttons-color': '--link-btn-base-color',
  'card-color': '--card-base-color',
  'on-card-color': '--card-fill-color',
  'footer-color': '--footer-base-color',
  'on-footer-text-color': '--footer-fill-color',
}

const transparencyMap: __KeyValue = {
  '-tr00': '00',
  '-tr05': '0d',
  '-tr10': '1a',
  '-tr20': '33',
  '-tr25': '40',
  '-tr30': '4d',
  '-tr40': '66',
  '-tr50': '80',
  '-tr60': '9a',
  '-tr70': 'b3',
  '-tr75': 'c0',
  '-tr80': 'cd',
  '-tr90': 'e6',
}

const applyTransparencyProps = [
  '--color-primary',
  '--color-background',
  '--color-foreground',
  '--link-btn-base-color',
  '--color-neutral',
]

const addTransparenciesMutator = (cssProps: __KeyValue, colorKey: string, colorValue: string) => {
  if (cssProps && applyTransparencyProps.includes(colorKey) && colorValue) {
    let parsedColor = parseColor(colorValue)
    if (parsedColor.length > 6) {
      parsedColor = parsedColor.substring(0, 6)
    }
    Object.keys(transparencyMap).forEach(key => {
      const value = transparencyMap[key]
      cssProps[colorKey + key] = `#${parsedColor}${value}`
    })
  }
}

const applyContrastProps = [
  {
    colorKey: '--link-btn-base-color',
    keyToSet: '--link-btn-fill-color',
    useDefinedColorFirst: true,
    light: 'fff',
    dark: '000',
  },
  {
    colorKey: '--color-background',
    keyToSet: '--color-important',
    light: 'e97872',
    dark: 'a03123',
  },
]
const addContrastColorMutator = (
  cssProps: __KeyValue,
  colorKey: string,
  colorValue: string,
  lightColor?: string,
  darkColor?: string,
) => {
  const prop = applyContrastProps.find(ck => ck.colorKey === colorKey)
  if (cssProps && prop?.colorKey && colorValue) {
    const parsedColor = parseColor(colorValue)
    const lightColorToUse = parseColor(
      prop.useDefinedColorFirst && lightColor ? lightColor : prop.light,
    )
    const darkColorToUse = parseColor(
      prop.useDefinedColorFirst && darkColor ? darkColor : prop.dark,
    )
    const lightContrast = calculateContrastRatio(parsedColor, lightColorToUse)
    const darkContrast = calculateContrastRatio(parsedColor, darkColorToUse)
    const highestContrast = Math.max(lightContrast.ratio, darkContrast.ratio)
    if (highestContrast > 4) {
      if (lightContrast.ratio > darkContrast.ratio) {
        cssProps[prop.keyToSet] = `#${lightColorToUse}`
      } else {
        cssProps[prop.keyToSet] = `#${darkColorToUse}`
      }
    }
  }
}

const mapBrandingProps = (
  props: __KeyValue,
): { cssProps: __KeyValue; brandingProps: __KeyValue } => {
  const cssProps: __KeyValue = {}
  const brandingProps: __KeyValue = {}
  Object.keys(props).forEach(key => {
    if (cssPropsMap[key]) {
      cssProps[cssPropsMap[key]] = props[key]
      addTransparenciesMutator(cssProps, cssPropsMap[key], props[key])
      addContrastColorMutator(
        cssProps,
        cssPropsMap[key],
        props[key],
        props['white-color'],
        props['dark-color'],
      )
    } else {
      if (key === 'client-contact-phone' && props[key]) {
        brandingProps[key + '-link'] = 'tel:' + props[key].replace(/\D/g, '')
      }
      brandingProps[key] = props[key]
    }
  })
  return { cssProps, brandingProps }
}

const brandingSettingsKey = 'branding_settings'
type brandingSettingKeys = 'brandingProps' | 'cssProps'
const brandingSettings = new StorageFactory<brandingSettingKeys>(
  brandingSettingsKey,
  global.localStorage,
)

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

const BrandingContext = createContext<BrandingContextType>(defaultState)

const BrandingContextWrapper: FunctionComponent<Props> = ({ children }) => {
  const { orgId, subId } = useAppContext()
  const showPseudoBranding = testFeatureActive('showPseudoBranding')
  const [branding, setBranding] = useState<__KeyValue>({})

  const fetchBranding = useCallback(async () => {
    if (orgId && subId) {
      const response = await customFetch(`branding/client/${orgId}/${subId}`, { method: 'GET' })
      const newBranding: __KeyValue = {}
      if (response?.ok) {
        const json = await response.json()
        Object.keys(json).forEach(key => {
          const value = json[key]
          newBranding[key] = value
        })
      }
      setBranding(newBranding)
    }
  }, [orgId, subId])

  const themeToUse = useMemo(() => {
    const showApiBrandingDefault = testFeatureActive('showApiBrandingDefault')
    const showPseudoBrandingWmd = testFeatureActive('showPseudoBrandingWmd')
    const showPseudoBrandingBlue = testFeatureActive('showPseudoBrandingBlue')
    const showPseudoBrandingBlue2 = testFeatureActive('showPseudoBrandingBlue2')
    const showPseudoBrandingGawdAwful = testFeatureActive('showPseudoBrandingGawdAwful')
    const showPseudoBrandingDark = testFeatureActive('showPseudoBrandingDark')
    if (showPseudoBrandingWmd) {
      return brandingThemes.wmd
    }
    if (showPseudoBrandingBlue) {
      return brandingThemes.blue
    }
    if (showPseudoBrandingBlue2) {
      return brandingThemes.blue2
    }
    if (showPseudoBrandingGawdAwful) {
      return brandingThemes.awful
    }
    if (showPseudoBrandingDark) {
      return brandingThemes.dark
    }
    if (branding && showApiBrandingDefault) {
      // HACK: until the api is updated with new values
      if (!branding['login-logo'] && branding['logo-1']) {
        branding['login-logo'] = branding['logo-1']
      }
      if (!branding['header-logo-small'] && branding['logo-2']) {
        branding['header-logo-small'] = branding['logo-2']
      }
      if (!branding['footer-logo'] && branding['logo-3']) {
        branding['footer-logo'] = branding['logo-3']
      }
      if (!branding['print-logo'] && branding['logo-4']) {
        branding['print-logo'] = branding['logo-4']
      }
      // END HACK
      return branding
    }
    return brandingThemes.default
  }, [branding])

  const propsToUse = useMemo(() => {
    return mapBrandingProps(themeToUse)
  }, [themeToUse])

  const [brandingProps, _setBrandingProps] = useState<__KeyValue>(propsToUse.brandingProps)
  const [cssProps, _setCssProps] = useState<__KeyValue>(propsToUse.cssProps)

  const setBrandingProps = (brandingProps: __KeyValue) => {
    if (brandingProps) {
      brandingSettings.set('brandingProps', JSON.stringify(brandingProps))
      _setBrandingProps(brandingProps)
    }
  }

  const setCssProps = (cssProps: __KeyValue) => {
    if (cssProps) {
      brandingSettings.set('cssProps', JSON.stringify(cssProps))
      _setCssProps(cssProps)
    }
  }

  const updateBrandingProp = (name: string, value: string) => {
    const newBrandingProps = Object.assign({}, brandingProps)
    if (name && (value || value === '')) {
      newBrandingProps[name] = value
    }
  }

  const getBrandingProp = (name: string) => {
    if (name && (brandingProps[name] || brandingProps[name] === '') && showPseudoBranding) {
      return brandingProps[name]
    }
    return ''
  }

  const brandingState: BrandingContextType = {
    brandingProps: showPseudoBranding ? brandingProps : noBrandingProps,
    cssProps: showPseudoBranding ? cssProps : {},
    setBrandingProps,
    setCssProps,
    updateBrandingProp,
    getBrandingProp,
  }

  const resurrectState = () => {
    const storedBrandingProps = brandingSettings.get('brandingProps')
    if (storedBrandingProps) {
      const formattedBrandingProps = JSON.parse(storedBrandingProps)
      _setBrandingProps(formattedBrandingProps)
    }
  }

  useEffect(() => {
    resurrectState()
  }, [])

  useEffect(() => {
    if (orgId && subId) {
      fetchBranding()
    }
  }, [fetchBranding, orgId, subId])

  useEffect(() => {
    setCssProps(propsToUse.cssProps)
    setBrandingProps(propsToUse.brandingProps)
  }, [propsToUse])

  return <BrandingContext.Provider value={brandingState}>{children}</BrandingContext.Provider>
}

export const useBranding = () => {
  return useContext(BrandingContext)
}

export type { BrandingContextType }

export default BrandingContextWrapper
