// Blue Cross of Idaho Colors
export const blueBrandingProps: __KeyValue = {
  'primary-x-light-color': '#F2F7FA',
  'primary-light-color': '#D6B861',
  'primary-color': '#003764',
  'primary-dark-color': '#003764',
  'secondary-color': '#6C3175',
  'on-page-text-color': '#111',
  'header-color': '#003764',
  'on-header-text-color': '#fff',
  'subheader-color': '#F2F7FA',
  'on-subheader-text-color': '#111',
  'links-buttons-color': '#003764',
  'card-color': '#F2F7FA',
  'on-card-color': '#111',
  'footer-color': '#EEEEEE',
  'on-footer-text-color': '#003764',
  'dark-color': '#003A47',
  'white-color': '#ffffff',
  'font-family': '"Nunito Sans", sans-serif',
  'font-family-decorative': 'Georgia, serif',
  'font-size-base': '16px',
  'login-logo': `https://learn.healthwise.net/images/healthSystemsBlue.png`,
  'header-logo-small': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'footer-logo': `https://learn.healthwise.net/images/healthSystemsBlue.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'search-title-english-text': 'Browse Health Resources',
  'search-title-spanish-text': 'Explorar recursos de salud',
  'subheader-title-english-text':
    'Stay informed with insightful tips and information on how to get the most out of your recovery.',
  'subheader-title-spanish-text':
    'Manténgase informado con valiosos consejos e información sobre cómo aprovechar al máximo su recuperación.',
  'client-contact-us-link': 'https://www.healthwise.org/contact.aspx',
  'client-contact-address': '2601 N. Bogus Basin Rd., Boise, Idaho, 83702',
}

// St. Luke's Colors
export const blue2BrandingProps: __KeyValue = {
  'primary-x-light-color': '#e8f3fb',
  'primary-light-color': '#5fa4e2',
  'primary-color': '#0086cf',
  'primary-dark-color': '#002c77',
  'secondary-color': '#cc0000',
  'on-page-text-color': '#111',
  'header-color': '#fff',
  'on-header-text-color': '#002c77',
  'subheader-color': '#e8f3fb',
  'on-subheader-text-color': '#002c77',
  'links-buttons-color': '#002c77',
  'card-color': '#fff',
  'on-card-color': '#002c77',
  'footer-color': '#002c77',
  'on-footer-text-color': '#fff',
  'dark-color': '#002c77',
  'white-color': '#ffffff',
  'font-family': 'neue helvetica w01,helvetica neue,Helvetica,Arial,sans-serif',
  'font-family-decorative': 'neue helvetica w01,helvetica neue,Helvetica,Arial,sans-serif',
  'font-size-base': '16px',
  'login-logo': `https://learn.healthwise.net/images/healthSystemsBlue2.png`,
  'header-logo-small': `https://learn.healthwise.net/images/healthSystemsBlue2.png`,
  'footer-logo': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'logo-url': '/',
  'search-title-english-text': 'Search Health Topics and Wellness Articles',
  'search-title-spanish-text': 'Busque temas de salud y artículos de bienestar.',
  'subheader-title-english-text':
    'Discover convenient and accessible health resources from the comfort of your home on your computer or smartphone. ',
  'subheader-title-spanish-text':
    'Descubra recursos de salud convenientes y accesibles desde la comodidad de su hogar en su computadora o teléfono inteligente.',
  'client-contact-us-link': 'https://www.healthwise.org/contact.aspx',
  'client-contact-address': '2601 N. Bogus Basin Rd., Boise, Idaho, 83702',
}

export const gawdAwfulBrandingProps: __KeyValue = {
  'primary-x-light-color': '#ffa49466',
  'primary-light-color': '#ff8e7a',
  'primary-color': '#ff6347ff',
  'primary-dark-color': '#fa2600',
  'secondary-color': '#FF00FF',
  'dark-color': '#200500',
  'white-color': 'rgba(255,255,255,1)',
  'neutral-color': '#401eff',
  'neutral-dark-color': '#140084',
  'neutral-medium-color': '#2000d0',
  'page-background-color': 'rgb(255,255,255)',
  'on-page-text-color': '#323232',
  'links-buttons-color': '#200500',
  'card-color': '#f7ffc866',
  'on-card-color': '#b77600',
  'footer-color': '#1b5233',
  'on-footer-text-color': '#ebc9ed',
  'header-color': '#ff94ef',
  'on-header-text-color': '#003308',
  'subheader-color': '#bf47ff',
  'on-subheader-text-color': '#e3ff47',
  'font-family': 'Verdana, sans-serif',
  'font-family-decorative': "'Brush Script MT', cursive",
  'font-size-base': '18px',
  'login-logo': `https://learn.healthwise.net/images/healthSystemsColor.png`,
  'header-logo-small': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'footer-logo': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'logo-url': '/',
  'search-title-english-text': 'Browse Health Resources',
  'search-title-spanish-text': 'Explorar recursos de salud',
  'client-contact-phone': '1-800-706-9646',
  'client-contact-address': '2601 N. Bogus Basin Rd., Boise, Idaho, 83702',
}

export const darkBrandingProps: __KeyValue = {
  'primary-x-light-color': '#c1adea',
  'primary-light-color': '#b299e5',
  'primary-color': '#9370DB',
  'primary-dark-color': '#7447d1',
  'secondary-color': '#db9370',
  'dark-color': '#242424',
  'white-color': 'white',
  'neutral-color': '#d2d2d2',
  'neutral-dark-color': '#767676',
  'neutral-medium-color': '#ababab',
  'page-background-color': '#242424',
  'on-page-text-color': '#efefef',
  'links-buttons-color': '#b299e5',
  'card-color': '#242424',
  'on-card-color': '#efefef',
  'footer-color': '#333',
  'on-footer-text-color': '#c1adea',
  'header-color': '#9370DB',
  'on-header-text-color': '#ffffff',
  'subheader-color': '#333',
  'on-subheader-text-color': '#c1adea',
  'font-family': "'Roboto', Oxygen, sans-serif",
  'font-family-decorative': "'Roboto Slab', Palatino, serif",
  'font-size-base': '16px',
  'login-logo': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'header-logo-small': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'footer-logo': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'logo-url': '/',
  'search-title-english-text': 'Search Health Information',
  'search-title-spanish-text': 'Buscar información de salud',
  'subheader-title-english-text':
    'Please check out health-related videos or articles that your provider has shared with you.',
  'subheader-title-spanish-text':
    'Consulte los videos o artículos relacionados con la salud que su proveedor haya compartido con usted.',
  'client-contact-us-link': 'https://www.healthwise.org/contact.aspx',
  'client-contact-phone': '1-800-706-9646',
  'client-contact-address': '2601 N. Bogus Basin Rd., Boise, Idaho, 83702',
}

export const wmdBrandingProps: __KeyValue = {
  'primary-x-light-color': '#7bced3',
  'primary-light-color': '#FF5236',
  'primary-color': 'rgb(27, 81, 98)',
  'primary-dark-color': '#0F245F',
  'secondary-color': '#f0543b',
  'secondary-x-light-color': '#4ea8de',
  'dark-color': '#242424',
  'white-color': '#fff',
  'neutral-color': '#676767',
  'neutral-dark-color': '#424242',
  'neutral-medium-color': '#727272',
  'page-background-color': '#fff',
  'on-page-text-color': 'rgb(22, 65, 78)',
  'links-buttons-color': '#263474',
  'card-color': '#fff',
  'on-card-color': '#54565B',
  'footer-color': '#F7F5EB',
  'on-footer-text-color': '#263474',
  'header-color': '#F7F5EB',
  'on-header-text-color': '#263474',
  'subheader-color': '#F7F5EB',
  'on-subheader-text-color': '#1B5162',
  'font-family': '"Plus Jakarta Sans", sans-serif',
  'font-family-decorative': '"GT-Super-Display-Bold", serif',
  'font-size-base': '16px',
  'login-logo': `https://webmdignite.com/sites/default/files/2023-03/WebMD-Ignite-Horizontal-Logo_full-color.png`,
  'header-logo-small': `https://webmdignite.com/sites/default/files/2023-03/WebMD-Ignite-Horizontal-Logo_full-color.png`,
  'footer-logo': `https://webmdignite.com/sites/default/files/2023-03/WebMD-Ignite-Horizontal-Logo_full-color.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'logo-url': '/',
  'search-title-english-text': 'Search Health Library',
  'search-title-spanish-text': 'Buscar en la biblioteca de salud',
  'subheader-title-english-text':
    'This dashboard is for your health resources. Check out any videos or articles that your doctor has shared with you.',
  'subheader-title-spanish-text':
    'Su médico ha compartido estos recursos con usted. También puede buscar en la biblioteca de salud para obtener más información.',
  'client-contact-us-link': 'https://webmdignite.com/contact-us',
  'client-contact-phone': '1-212-624-3700',
  'client-contact-address': '283-299 Market St. Newark, NJ 07102',
}

export const defaultBrandingProps: __KeyValue = {
  'primary-x-light-color': '#e3f5f0',
  'primary-light-color': '#aae2d0',
  'primary-color': '#0d8484',
  'primary-dark-color': '#006272',
  'secondary-color': '#dd372f',
  'secondary-x-light-color': '#e97872',
  'dark-color': '#242424',
  'white-color': '#fff',
  'neutral-color': '#676767',
  'neutral-dark-color': '#424242',
  'neutral-medium-color': '#727272',
  'page-background-color': 'white',
  'on-page-text-color': '#424242',
  'links-buttons-color': '#006272',
  'card-color': '#fff',
  'on-card-color': '#424242',
  'footer-color': '#424242',
  'on-footer-text-color': 'white',
  'header-color': '#0d8484',
  'on-header-text-color': '#ffffff',
  'subheader-color': '#e3f5f0',
  'on-subheader-text-color': '#262626',
  'font-family': "'Roboto', Oxygen, sans-serif",
  'font-family-decorative': "'Roboto Slab', Palatino, serif",
  'font-size-base': '16px',
  'login-logo': `https://learn.healthwise.net/images/healthSystemsColor.png`,
  'header-logo-small': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'footer-logo': `https://learn.healthwise.net/images/healthSystemsWhite.png`,
  'print-logo': `https://learn.healthwise.net/images/healthSystemsBlack.png`,
  'logo-url': '/',
  'search-title-english-text': 'Search Health Library',
  'search-title-spanish-text': 'Buscar en la biblioteca de salud',
  'subheader-title-english-text':
    'This dashboard is for your health resources. Check out any videos or articles that your doctor has shared with you.',
  'subheader-title-spanish-text':
    'Su médico ha compartido estos recursos con usted. También puede buscar en la biblioteca de salud para obtener más información.',
  'client-contact-us-link': 'https://www.healthwise.org/contact.aspx',
  'client-contact-phone': '1-800-706-9646',
  'client-contact-address': '2601 N. Bogus Basin Rd., Boise, Idaho, 83702',
}
